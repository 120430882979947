import React from 'react'

import Layout from '../components/layout'

const NotFound: React.FC<any> = () => {
  return (
    <Layout tocEnabled={false}>
      <h1>Something is wrong here!</h1>
      This is a 404 error, which means you have clicked on a bad link or entered
      an invalid URL.
    </Layout>
  )
}

export default NotFound
